import * as React from 'react'
import './styles.css'
const WalmartMilitarySection = () => {
  return (
    <section className='walmart-military-section'>
      <div className='walmart-military-container'>
        <div className='walmart-military-section-title-container'>
          <h1 className='walmart-military-section-title'>
            Upload your resume and get matched to careers
          </h1>
        </div>
        <div className='walmart-military-section-form-container'>
          <iframe
            src='https://walmart.podglobal.com/embedded/sign-up/9b44b299-952a-42d1-a7ee-5ce49b0c937e/?from=https://walmart.podglobal.com'
            title='My Embedded Form'
            className='walmart-military-iframe'
          />
        </div>
      </div>
    </section>
  )
}

export default WalmartMilitarySection
