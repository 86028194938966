import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

import './styles.css'

function Input({ id, type, required, label, register, errors, watch }) {
  return (
    <div className='input-group'>
      <div className='input-wrapper'>
        <input
          type={type}
          id={id}
          {...register(id, {
            required: required,
          })}
          className='form-input'
          placeholder=' '
        />
        <label className='input-label' htmlFor={id}>
          {label}
          {required && <span className='required'>*</span>}
        </label>
        {errors[id] ? (
          <ExclamationCircleIcon className='icon icon-error' />
        ) : (
          watch(id) && <CheckIcon className='icon icon-success' />
        )}
        {errors[id] && <span className='error'>Valid {label} is required</span>}
      </div>
    </div>
  )
}

export default Input
