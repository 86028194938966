import * as React from 'react'
// components
import Header from '../components/layouts/Header'
import Footer from '../components/layouts/Footer'
import CustomerSection from '../components/sections/Customer'

const Customer = () => {
  return (
    <>
      <div id='smooth-wrapper'>
        <div id='smooth-content'>
          <div className='podio'>
            <CustomerSection />
            <Footer />
          </div>
        </div>
      </div>
      <Header />
    </>
  )
}

export default Customer
