import * as React from 'react'
import classNames from 'classnames'
import Logo from '../../../assets/imgs/logo.svg'
import './styles.css'

const Header = () => {
  const [open, setOpen] = React.useState(false)

  React.useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth > 750 && open) {
        setOpen(false)
      }
    }

    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [open])

  const burgerClassName = classNames('burger', { open: open })
  const navClassName = classNames('menu', { open: open })
  const overlayClassName = classNames('overlay', { open: open })

  React.useEffect(() => {
    const removeHash = () => {
      if (window.location.hash) {
        setTimeout(() => {
          window.history.replaceState(null, '', window.location.pathname)
        }, 500)
      }
    }

    window.addEventListener('hashchange', removeHash, false)

    return () => {
      window.removeEventListener('hashchange', removeHash)
    }
  }, [])

  return (
    <>
      <header>
        <div className='box'>
          <div className='header-content'>
            <a href='/'>
              <img src={Logo} alt='Logo' />
            </a>
            <div className='nav-menu'>
              <div className='menu-desktop'>
                <a href='/'>Overview</a>
                <a href='/career-connector'>Career Connector</a>
                <a href='/learning-management'>LMS</a>
              </div>
              <button
                className={burgerClassName}
                onClick={() => setOpen(!open)}
              >
                <div className='line' />
                <div className='line' />
                <div className='line' />
              </button>
              <nav className={navClassName}>
                <div className='menu-mobile'>
                  <a href='/'>Overview</a>
                  <a href='/career-connector'>Career Connector</a>
                  <a href='/learning-management'>LMS</a>
                </div>
                <a href='/#news' onClick={() => setOpen(false)}>
                  News
                </a>
                <a href='/#team' onClick={() => setOpen(false)}>
                  Team
                </a>
                <a href='/customer' onClick={() => setOpen(false)}>
                  Client Portal
                </a>
                <a href='/contact' onClick={() => setOpen(false)}>
                  Contact Us
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div
        className={overlayClassName}
        onClick={() => {
          setOpen(false)
        }}
      />
    </>
  )
}

export default Header
