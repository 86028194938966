import { maps } from '../../../../../constants/news'
import { motion } from 'motion/react'
import './styles.css'

const Maps = () => {
  return (
    <div className='maps-wrapper padding-top-4'>
      {maps.map((map, index) => (
        <motion.div
          key={index}
          id={`map-${index + 1}`}
          initial={{ opacity: 0, y: '15px' }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut', delay: index * 0.25 }}
          className='maps-wrapper'
          viewport={{ once: true }}
        >
          <span className='map-title'>{map.title}</span>
          <img
            src={map.image}
            alt={`map-${index + 1}`}
            style={index === 0 ? { marginBottom: -15 } : {}}
          />
        </motion.div>
      ))}
    </div>
  )
}

export default Maps
