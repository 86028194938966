import { BrowserRouter, Routes, Route } from 'react-router-dom'
// components
import Home from './views/Home'
import Contact from './views/Contact'
import Customer from './views/Customer'
import CareerConnector from './views/CareerConnector'
import LearningManagement from './views/LearningManagement'
import WalmartMilitaryCareers from './views/WalmartMilitary'
// styles
import './styles/global.css'
import { Helmet } from 'react-helmet'

function App() {
  const isPod = window.location.hostname === 'podglobal.com'
  const isWalmart =
    window.location.hostname === 'walmartmilitarycareers.podglobal.com'

  return (
    <BrowserRouter>
      <Helmet>
        <title>POD Global</title>
        <meta
          name='description'
          content="Pod's Talent Intelligence platform is a comprehensive solution designed to future-proof workforces by providing corporations and organizations with actionable insights into their talent landscape."
        />
        <meta property='og:title' content='POD Global' />
        <meta
          property='og:description'
          content="Pod's Talent Intelligence platform is a comprehensive solution designed to future-proof workforces by providing corporations and organizations with actionable insights into their talent landscape."
        />
      </Helmet>
      {isPod ? (
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/customer' element={<Customer />} />
          <Route path='/career-connector' element={<CareerConnector />} />
          <Route path='/learning-management' element={<LearningManagement />} />
        </Routes>
      ) : isWalmart ? (
        <Routes>
          <Route path='/' element={<WalmartMilitaryCareers />} />
        </Routes>
      ) : (
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/customer' element={<Customer />} />
          <Route path='/career-connector' element={<CareerConnector />} />
          <Route path='/learning-management' element={<LearningManagement />} />
          <Route
            path='/walmart-military-careers'
            element={<WalmartMilitaryCareers />}
          />
        </Routes>
      )}
    </BrowserRouter>
  )
}

export default App
