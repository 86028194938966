import Input from '../../../../ui/Input/input'
import { useForm } from 'react-hook-form'
import * as React from 'react'
import { motion } from 'motion/react'
import './styles.css'

const AccessForm = ({ onSubmit, submitError }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  return (
    <motion.div
      key='password'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='password-overlay'
    >
      <form onSubmit={handleSubmit(onSubmit)} className='password-form'>
        <p className='sub-title'>
          Please enter your information to access this section.
        </p>
        <div className='input-wrapper'>
          <Input
            id='email'
            type='text'
            required={true}
            label='Email'
            register={register}
            errors={errors}
            watch={watch}
          />
          <Input
            id='password'
            type='text'
            required={true}
            label='Password'
            register={register}
            errors={errors}
            watch={watch}
          />
          {submitError && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='error-message'
            >
              Incorrect details. Please try again.
            </motion.p>
          )}
          <button type='submit' className='submit-button'>
            Submit
          </button>
        </div>
      </form>
    </motion.div>
  )
}

export default AccessForm
