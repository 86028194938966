import * as React from 'react'
import { AnimatePresence, motion } from 'motion/react'
import { BACKEND_URL } from '../../../constants/env'
import './styles.css'
import AccessForm from './components/AccessForm'
// assets
import UpskillingPathways from '../../../assets/imgs/customer/upskillingPathways.png'
import { ReactComponent as UpskillingPathwaysArrow } from '../../../assets/imgs/customer/upskillingPathwaysArrow.svg'
import MatchPlus from '../../../assets/imgs/customer/matchPlus.png'
import Matching from '../../../assets/imgs/customer/matching.png'
import CourseraLogo from '../../../assets/imgs/customer/CourseraLogo.png'
import Glow from '../../glows'

const UpskillSection = () => {
  const [submitError, setSubmitError] = React.useState(false)

  const [isAuthenticated, setIsAuthenticated] = React.useState(() => {
    return sessionStorage.getItem('upskillAuth') === 'true'
  })

  const onSubmit = async (data) => {
    try {
      const requestBody = {
        email: data.email,
        password: data.password,
      }

      const response = await fetch(`${BACKEND_URL}/api/customer/access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        setSubmitError(true)
        return
      }
      setIsAuthenticated(true)
      sessionStorage.setItem('upskillAuth', 'true')
    } catch (error) {
      setSubmitError(true)
    }
  }

  return (
    <AnimatePresence mode='wait'>
      {isAuthenticated ? (
        <section className='customer-section'>
          <motion.div
            key='content'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='box'
          >
            <div className='customer-container'>
              <div className='image-container'>
                <Glow />
                <div className='image-content-container'>
                  <div className='image-wrapper'>
                    <img
                      src={Matching}
                      alt='POD application with matching'
                      className='image'
                    />
                  </div>
                </div>
              </div>
              <div className='text-container'>
                <h1 className='customer-main-title main-title'>Opportunity Matching</h1>
                <p className='sub-title'>Pod Match Level</p>
                <p className='text'>
                  The Pod keyword extractor identifies important keywords inside
                  a user's profile, including resume, work history, skills,
                  education and certificates.
                </p>
                <p className='text'>
                  Pod adds weighting to various elements of a job post, such as
                  title, post length and keyword recurrence. Pod uses a
                  color-coded meter to highlight opportunities that a employee
                  or a prospective employee may find interesting.
                </p>
              </div>
            </div>
            <div className='customer-container'>
              <div className='text-container'>
                <h1 className='customer-main-title main-title'>Upskilling Pathways</h1>
                <p className='sub-title'>The ultimate upskilling</p>
                <p className='text'>
                  With Pod's new upskilling feature, employees can explore
                  upskilling pathways relevant to each opportunity. In the image
                  to the right you can see Coursera courses that would be
                  relevant to anyone interested in this IT Specialist position.
                </p>
                <p className='text'>
                  Upskilling and hiring ambitious employees within your own
                  organization is worth millions of dollars to your bottom-line
                  and the boost to employee morale is priceless.
                </p>

                <div className='integration-section'>
                  <p className='integration-title'>Integrates with:</p>
                  <ul className='integration-list'>
                    <li>
                      <img
                        src={CourseraLogo}
                        alt='Coursera Logo'
                        className='coursera-logo'
                      />
                    </li>
                    <li>Your company's existing education programs.</li>
                    <li>College and university courses.</li>
                  </ul>
                </div>
              </div>

              <div className='image-container'>
                <Glow position='right' />

                <div className='image-content-container'>
                  <div className='image-wrapper'>
                    <img
                      src={UpskillingPathways}
                      alt='POD application with upskilling pathways'
                      className='image'
                    />
                    <div className='arrow'>
                      <UpskillingPathwaysArrow />
                    </div>
                  </div>
                  <p className='text'>
                    When employees and prospective employees view opportunities,
                    they'll be able to see which relevant courses and
                    credentials will make them a good fit.
                  </p>
                </div>
              </div>
            </div>
            <div className='customer-container'>
              <div className='image-container'>
                <Glow />

                <div className='image-content-container'>
                  <div className='image-wrapper'>
                    <img
                      src={MatchPlus}
                      alt='POD application with match plus'
                      className='image'
                    />
                  </div>
                </div>
              </div>
              <div className='text-container'>
                <h1 className='customer-main-title main-title'>Match+</h1>
                <p className='sub-title'>Will inspire your workforce</p>
                <p className='text'>
                  With match+ employees and prospective employees can instantly
                  see how upskilling will improve their fit for job
                  opportunities.
                </p>
                <p className='text'>
                  Once an employee adds a course they will see second score in
                  the discovery area that shows how much more qualified they
                  will be for a particular opportunity if they were to upskill
                  with that course.
                </p>
                <p className='text'>
                  Being able to instantly feel the practical value of upskilling
                  is like a superpower for your workforce. This boosts employee
                  engagement, helps you hire within and provides both macro and
                  individual insights into what sorts of upskilling your
                  workforce is looking for.
                </p>

                <div className='integration-section'>
                  <p className='integration-title'>Match+ works with:</p>
                  <ul className='integration-list'>
                    <li>
                      Popular education content providers like &nbsp;
                      <img
                        src={CourseraLogo}
                        alt='Coursera Logo'
                        className='coursera-logo'
                      />
                    </li>
                    <li>Your company's own upskilling curriculum.</li>
                    <li>Colleges and universities.</li>
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        </section>
      ) : (
        <AccessForm onSubmit={onSubmit} submitError={submitError} />
      )}
    </AnimatePresence>
  )
}

export default UpskillSection
