import * as React from 'react'
import './styles.css'
// assets
import CareerPeople from '../../../assets/imgs/career/careerPeople.png'
import CareerStats from '../../../assets/imgs/career/careerStats.png'
import CareerJobs from '../../../assets/imgs/career/careerJobs.png'
import Glow from '../../glows'

const CareerConnectorSection = () => {
  return (
    <section className='career-connector-section'>
      <div className='box'>
        <div className='career-connector-container'>
          <div className='customer-image-container'>
            <Glow />
            <img
              src={CareerPeople}
              alt='POD application with alumni and recomendations'
              className='customer-image'
            />
          </div>
          <div className='text-container'>
            <p className='career-connector-title'>
              A Future-Ready Approach to Student Careers
            </p>
            <p className='career-text'>
              Most schools understand that the greatest gift they can give their
              students is a successful career—but too often, that crucial step
              is left to chance. Pod empowers forward-thinking schools to bridge
              the gap between education and employment, ensuring every student
              has access to real opportunities, not just theory.
            </p>
            <p className='career-connector-title'>
              Real-Time Access to Internships, Apprenticeships & Jobs
            </p>
            <p className='career-text'>
              Pod continuously integrates real-time job opportunities,
              internships, and apprenticeships from regional and global
              companies. Students can browse, sort, and apply for positions
              directly within the platform, making career discovery and
              achievement easier than ever.
            </p>
          </div>
        </div>
        <div className='career-connector-container'>
          <div className='text-container'>
            <p className='career-connector-title'>
              AI-Driven Job Matching & Personalized Notifications
            </p>
            <p className='career-text'>
              With Pod's AI-driven matching technology, students don't just
              search for jobs—they get matched to the right ones. They can also
              tag the types of roles they're interested in and receive real-time
              notifications when new opportunities arise.
            </p>
            <p className='career-connector-title'>
              Measurable Career Success: Track Student Outcomes
            </p>
            <p className='career-text'>
              For the first time, schools can measure how effective they are at
              helping students transition into the workforce. Pod captures and
              visualizes key metrics:
            </p>
            <ul className='career-list'>
              <li>How many students are securing jobs?</li>
              <li>Where are they going?</li>
              <li>What industries are hiring them?</li>
              <li>How well is the school preparing them for the future?</li>
            </ul>
            <p className='career-text'>
              This data isn't just useful—it's transformative. Schools can use
              it to improve career readiness programs and demonstrate their
              impact to prospective students and families.
            </p>
          </div>

          <div className='customer-image-container'>
            <Glow position='right' />

            <img
              src={CareerStats}
              alt='POD application with matches and statistics'
              className='customer-image'
            />
          </div>
        </div>
        <div className='career-connector-container'>
          <div className='customer-image-container'>
            <Glow />

            <img
              src={CareerJobs}
              alt='POD application with jobs and match scores'
              className='customer-image'
            />
          </div>
          <div className='text-container'>
            <p className='career-connector-title'>
              A Powerful Tool for Admissions & Recruitment
            </p>
            <p className='career-text'>
              Career outcomes are a critical factor in college selection. With
              Pod, schools can showcase their ability to connect students with
              real-world opportunities, helping them stand out in an
              increasingly competitive landscape.
            </p>
            <p className='career-connector-title'>Alumni Are for Life</p>
            <p className='career-text'>
              The benefits of Pod don't stop at graduation. Your students are
              your alumni forever, and Pod helps them stay connected—to you, to
              each other, and to new career opportunities. Many of the best
              mentors are alumni, and Pod makes it easy to facilitate
              mentorship, networking, and ongoing career support. By keeping
              alumni engaged, schools build stronger communities, foster
              lifelong loyalty, and provide lasting career support.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CareerConnectorSection
