import GlowSrc from '../../assets/imgs/LeftGlow.png'
import './styles.css'
const classNames = require('classnames');

function Glow({ position = 'left' }) {

  return (
    <img
      src={GlowSrc}
      alt=''
      className={classNames('glow', `${position}-glow`)}
    />
  )
}

export default Glow
