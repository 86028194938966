import classNames from 'classnames'
import './styles.css'
import { ReactComponent as QuoteIcon } from '../../assets/imgs/quoteIcon.svg'

function Quote({ quote, isHero }) {
  return (
    <div className={classNames({ 'quote-container': !isHero })}>
      {!isHero && <QuoteIcon className='quote-icon' />}
      <div>
        <p className={classNames(isHero ? 'hero-user-quote' : 'main-quote')}>
          {quote.quote}
        </p>
      </div>
      <div className='hero-feedback'>
        <img src={quote.avatar} alt={quote.alt} />
        <div>
          <p className='hero-feedback-name'>{quote.name}</p>
          <p
            className={classNames(
              { 'smaller-position': !isHero },
              'hero-feedback-position '
            )}
          >
            {quote.position}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Quote
