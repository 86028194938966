import OliviaHoltIvry from '../assets/imgs/hero/OliviaHoltIvry.png'
import BradNabors from '../assets/imgs/hero/BradNabors.png'
import NicholasFazzolari from '../assets/imgs/hero/NicholasFazzolari.png'
import BrendaNowicki from '../assets/imgs/hero/BrendaNowicki.png'

export const quotes = [
  {
    quote:
      '“Pod’s innovative approach allows us to connect strategic talent pipelines to career opportunities, accelerating the development of Walmart’s skills-first workforce.”',
    name: 'Brenda Nowicki',
    position: 'Walmart',
    avatar: BrendaNowicki,
    alt: 'Headshot of Brenda Nowicki',
  },
  {
    quote:
      '“With over 10,000 employees across the U.S., Pod gives us exceptional visibility into our talent, ensuring the right resources are in the right places to deliver high-quality care to our patients.”',
    name: 'Nicholas Fazzolari',
    position: 'PAM Health',
    avatar: NicholasFazzolari,
    alt: 'Headshot of Nicholas Fazzolari',
  },
  {
    quote:
      '"We were determined to take an innovative approach to achieve the best outcomes for our people, and partnering with Pod opened up new ways to bring this vision to life."',
    name: 'Brad Nabors',
    position: 'Home Partners of America',
    avatar: BradNabors,
    alt: 'Headshot of Brad Nabors',
  },
  {
    quote:
      '“We have worked closely with the Pod team and other corporate partners to make this project come to life.”',
    name: 'Olivia Holt-Ivry',
    position: 'U.S. Department of State',
    avatar: OliviaHoltIvry,
    alt: 'Headshot of Olivia Holt-Ivry',
  },
]
