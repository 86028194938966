import React from 'react'
// libs
import { motion, useAnimation } from 'motion/react'
import classNames from 'classnames'
// constants
import { hiddenStyles } from './constants'

const DropdownItem = ({ item }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const controls = useAnimation()

  const arrowClass = classNames('down', { 'open-list': isOpen })

  const toggleDropdown = () => {
    setIsOpen(!isOpen)

    if (isOpen) {
      controls.start({ opacity: 0, height: 0, margin: 0 })
    } else {
      controls.start({
        opacity: 1,
        height: 'auto',
        margin: '2.5rem 0',
        transition: { duration: 1.2 },
      })
    }
  }

  return (
    <div className='dropdown-item width-100'>
      <div className='dropdown-title width-100' onClick={toggleDropdown}>
        {item.label}
        <span className={arrowClass}></span>
      </div>
      <motion.div
        className='description dropdown-content'
        initial={hiddenStyles}
        animate={controls}
      >
        {isOpen && item.description}
      </motion.div>
    </div>
  )
}

export default DropdownItem
