import { useAnimate, useInView } from 'motion/react'
import { useEffect, useRef } from 'react'

export const useStartCardsAnimation = () => {
  const [overviewRef, animateOverview] = useAnimate()
  const [talentRef, animateTalent] = useAnimate()
  const [employeeRef, animateEmployee] = useAnimate()
  const [gearRef, animateGear] = useAnimate()
  const [chartRef, animateChart] = useAnimate()

  const employeeContainerRef = useRef(null)
  const isEmployeeInView = useInView(employeeContainerRef, { once: true })

  useEffect(() => {
    animateOverview(
      '#Path_45989',
      { pathLength: [0, 1] },
      {
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse',
        ease: 'linear',
      }
    )
    animateOverview(
      '#Group_46517',
      {
        x: [-700, -675],
        y: [25, 0],
      },
      {
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )

    animateOverview(
      '#Group_46513',
      {
        x: [-800, -775],
        y: [-150],
      },
      {
        duration: 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )
    animateOverview(
      '#Group_46515',
      {
        x: [-750],
        y: [-325, -300],
      },
      {
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )

    animateTalent(
      '#Group_46527',
      {
        x: [-2260],
        y: [640, 660],
      },
      {
        duration: 2.5,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )
    animateTalent(
      '#Group_46530',
      {
        y: [],
        x: [20, 40],
      },
      {
        duration: 2.5,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )
    animateTalent(
      '#Group_46529',
      {
        y: [75],
        x: [-10, 15],
      },
      {
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )
    animateTalent(
      '#Group_46516',
      {
        y: [460],
        x: [0, 25],
      },
      {
        duration: 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )

    animateTalent(
      '#Group_44299 rect',
      { width: [1, 80] },
      {
        duration: 3.5,
        ease: 'easeIn',
        onUpdate: () => {
          const width = parseFloat(
            document.querySelector('#Group_44299 rect')?.getAttribute('width')
          )
          if (!isNaN(width)) {
            const res = Math.round((width * 100) / 164)
            const tspan = document.querySelector('#_50_ tspan')
            if (tspan) {
              tspan.textContent = `${res}%`
            }
          }
        },
      }
    )

    animateTalent(
      '#Group_44299-2 rect',
      { width: [14, 104] },
      {
        duration: 4.5,
        ease: 'easeIn',
        onUpdate: () => {
          const width = parseFloat(
            document.querySelector('#Group_44299-2 rect')?.getAttribute('width')
          )
          if (!isNaN(width)) {
            const res = Math.round((width * 100) / 164)
            const tspan = document.querySelector('#_50_ tspan')
            if (tspan) {
              tspan.textContent = `${res}%`
            }
          }
        },
      }
    )

    animateTalent(
      '#Group_44297 rect',
      { width: [19, 109] },
      {
        duration: 4.5,
        ease: 'easeIn',
        onUpdate: () => {
          const width = parseFloat(
            document.querySelector('#Group_44297 rect')?.getAttribute('width')
          )
          if (!isNaN(width)) {
            const res = Math.round((width * 100) / 175)
            const tspan = document.querySelector('#_62_ tspan')
            if (tspan) {
              tspan.textContent = `${res}%`
            }
          }
        },
      }
    )

    animateTalent(
      '#Line_1264',
      { x2: [30, 140] },
      { duration: 5, ease: 'easeIn' }
    )

    animateTalent(
      '#Line_1284',
      { x2: [25, 133] },
      { duration: 5, ease: 'easeIn' }
    )

    animateTalent(
      '#Line_1284-2',
      { x2: [22, 122] },
      { duration: 5, ease: 'easeInOut' }
    )

    animateTalent(
      '#Line_1291-2',
      { x2: [21, 118] },
      { duration: 5, ease: 'easeInOut' }
    )

    animateTalent(
      '#Line_1291-3',
      { x2: [20, 109] },
      { duration: 5, ease: 'easeInOut' }
    )

    if (isEmployeeInView) {
      animateEmployee(
        '#Group_46785',
        {
          x: [-715, -740],
          y: [-1450],
        },
        {
          duration: 3,
          repeat: Infinity,
          repeatType: 'reverse',
        }
      )

      animateEmployee(
        '#Group_46784',
        {
          x: [-5, 25],
          y: [-235, -220],
        },
        {
          duration: 2,
          repeat: Infinity,
          repeatType: 'reverse',
        }
      )

      animateEmployee(
        '.path-web-dev',
        { opacity: [0, 1] },
        { duration: 1, ease: 'easeOut' }
      )

      animateEmployee(
        '.web-dev-arrow',
        { opacity: [0, 1] },
        { duration: 1, ease: 'easeOut', delay: 0.4 }
      )

      animateEmployee(
        '.path-senior-dev',
        { opacity: [0, 1] },
        { duration: 1, ease: 'easeOut', delay: 0.8 }
      )

      animateEmployee(
        '.web-senior-arrow',
        { opacity: [0, 1] },
        { duration: 1, ease: 'easeOut', delay: 1.2 }
      )

      animateEmployee(
        '.path-full-dev',
        { opacity: [0, 1] },
        { duration: 1, ease: 'easeOut', delay: 1.4 }
      )
    }
    animateGear(
      '#Group_46869',
      {
        x: [5615, 5615],
        y: [4200, 4200],
        rotate: [0, 360],
      },
      {
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      }
    )

    animateGear(
      '#Path_46391',
      {
        x: [5650, 5650],
        y: [4175, 4175],
        rotate: [0, 360],
      },
      {
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      }
    )

    animateGear(
      '.gear-reverse',
      {
        x: [5675, 5675],
        y: [4100, 4100],
        rotate: [0, -360],
      },
      {
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      }
    )

    animateChart(
      '#chart-data',
      {
        x: [-10, 20],
      },
      {
        duration: 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )

    animateChart(
      '#chart-spider',
      {
        y: [0, 30],
      },
      {
        duration: 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )

    animateChart(
      '#chart-icon',
      {
        y: [0, 10],
        x: [-5, 20],
      },
      {
        duration: 2,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )

    animateChart(
      '#chart-map',
      {
        x: [-10, 30],
      },
      {
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse',
      }
    )
  }, [
    animateOverview,
    animateTalent,
    animateEmployee,
    animateGear,
    animateChart,
    isEmployeeInView,
  ])

  return {
    overviewRef,
    talentRef,
    employeeRef,
    gearRef,
    chartRef,
    employeeContainerRef,
  }
}
