import * as React from 'react'
// styles
import './styles.css'
import TeamGrid from './components/TeamGrid'
import Glow from '../../glows'


const TeamSection = () => (
  <section className='team-section'>
    <div className='team-container'>
      <Glow />
      <h2 className='main-title'>Team</h2>
      <TeamGrid />
    </div>
  </section>
)

export default TeamSection
