import NSFELogo from '../assets/imgs/hero/NSF.png'
import WalmartLogo from '../assets/imgs/hero/Walmart.svg'
import PAMLogo from '../assets/imgs/hero/PAM.svg'
import DoSLogo from '../assets/imgs/hero/DoS.png'
import { quotes } from './quotes'

export const DIRECTION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
}

export const testimonials = [
  {
    images: [
      {
        src: WalmartLogo,
        alt: "Walmart's logo",
      },
      {
        src: PAMLogo,
        alt: "PAM Health's logo",
      },
      {
        src: DoSLogo,
        alt: "Department of State's logo",
      },
      {
        src: NSFELogo,
        alt: "National Science Foundation's logo",
      },
    ],
    text: "Used by the world's leading organizations.",
  },
  ...quotes,
]
