import * as React from 'react'
// components
import Header from '../components/layouts/Header'
import HeroComponent from '../components/sections/HeroComponent'
import Footer from '../components/layouts/Footer'
import Overview from '../components/sections/Overview'
import News from '../components/sections/News'
import TeamSection from '../components/sections/Team'

const Home = () => {
  React.useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
      setTimeout(() => {
        window.history.replaceState(null, null, ' ')
      }, 500)
    }
  }, [])

  return (
    <>
      <div id='smooth-wrapper'>
        <div id='smooth-content'>
          <div className='podio'>
            <section>
              <HeroComponent />
            </section>
            <section>
              <Overview />
            </section>
            <section id='news'>
              <News />
            </section>
            <section id='team'>
              <TeamSection />
            </section>
            <Footer />
          </div>
        </div>
      </div>
      <Header />
    </>
  )
}

export default Home
