import Map1 from '../assets/imgs/news/news-map1.png'
import Map3 from '../assets/imgs/news/news-map3.png'
import Map2 from '../assets/imgs/news/news-map2.png'
import Map4 from '../assets/imgs/news/news-map4.png'
import Map5 from '../assets/imgs/news/news-map5.png'

export const maps = [
    {
      title: 'Artificial Intelligence',
      image: Map1,
    },
    {
      title: 'Automotive Manufacturing',
      image: Map2,
    },
    {
      title: 'Electric Battery Production',
      image: Map3,
    },
    {
      title: 'Information Technology',
      image: Map4,
    },
    {
      title: 'Software Development',
      image: Map5,
    },
  ];