import * as React from 'react'
// styles
import './styles.css'
import HeroCarousel from './components/HeroCarousel'

const HeroComponent = () => {

  return (
    <section className='hero-section'>
      <div className='box'>
        <div className='hero-grid'>
          <div className='hero-content'>
            <h2 className='main-title'>Workforce Intelligence</h2>
            <HeroCarousel />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroComponent
