import * as React from 'react'
import './styles.css'

const Footer = () => (
  <footer>
    <div className='box'>
      <div>
        {/* &#169; is the HTML symbol for Copyright */}
        <p>&#169; 2025 Pod Network, LLC</p>
      </div>
      <div className='footer-bottom'>
        <p>1500 Rosecrans, Suite 500, Manhattan Beach, CA 90266</p>
        <div className='footer-links'>
          <a
            href='https://web.atlasjobs.com/privacy-policy/'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
          <a
            href='https://web.atlasjobs.com/terms-and-conditions/'
            target='_blank'
            rel='noreferrer'
          >
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
