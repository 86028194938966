import { motion } from 'motion/react'
import { teamMembers } from '../../../../../constants/team'
// styles
import './styles.css'

const TeamGrid = () => {
  return (
    <div className='avatars'>
      {teamMembers.map((member, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1, ease: 'easeOut', delay: index * 0.25 }}
        >
          <img src={member.img} alt='' />
          <p className='owner-name'>{member.name}</p>
          <span className='owner-position'>{member.position}</span>
        </motion.div>
      ))}
    </div>
  )
}

export default TeamGrid
