// static
import JoWebber from '../assets/imgs/team/Jo-Webber.png'
import ChrisOtt from '../assets/imgs/team/Chris-Ott.png'
import DmytroShepeliev from '../assets/imgs/team/Dmytro-Shepeliev.png'
import KateGroves from '../assets/imgs/team/Kate-Groves.png'
import JanineKoeneke from '../assets/imgs/team/Janine-Koeneke.png'
import CorbinKezar from '../assets/imgs/team/Corbin-Kezar.png'

export const teamMembers = [
  {
    img: JoWebber,
    name: 'Jo Webber',
    position: 'CEO, Founder',
  },
  {
    img: ChrisOtt,
    name: 'Chris Ott',
    position: 'Head of Product, Cofounder',
  },
  {
    img: DmytroShepeliev,
    name: 'Dmytro Shepeliev',
    position: 'Head of Development',
  },
  {
    img: KateGroves,
    name: 'Kate Groves',
    position: 'Head of Implementation',
  },
  {
    img: JanineKoeneke,
    name: 'Janine Koeneke',
    position: 'Chief Grant Development Officer',
  },
  {
    img: CorbinKezar,
    name: 'Corbin Kezar',
    position: 'Head of Client Success',
  }
]
