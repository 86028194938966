import * as React from 'react'
// components
import WalmartMilitarySection from '../components/sections/WalmartMilitary'
import { Helmet } from 'react-helmet'

const WalmartMilitaryCareers = () => {
  return (
    <>
      <Helmet>
        <title>Walmart Military Careers</title>
        <meta
          name='description'
          content='Match your resume to careers at Walmart.'
        />
        <meta property='og:title' content='Walmart Military Careers' />
        <meta
          property='og:description'
          content='Match your resume to careers at Walmart.'
        />
      </Helmet>
      <div id='smooth-wrapper'>
        <div id='smooth-content'>
          <div className='podio'>
            <WalmartMilitarySection />
          </div>
        </div>
      </div>
    </>
  )
}

export default WalmartMilitaryCareers
