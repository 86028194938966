// static
import LogoNSF from '../../../assets/imgs/overview/logoNSF.png'
// styles
import './styles.css'
// libs
import * as React from 'react'
import { motion } from 'motion/react'
// components
import AnimatedContainer from '../../ui/AnimatedContainer/animatedContainer'
import Maps from './components/maps'
// constants
import {
  descriptionAnimation,
  titleAnimation,
} from '../../../constants/animation'
import Glow from '../../glows'

const News = ({ scrollRef }) => (
  <section ref={scrollRef} className='overview-section'>
    <div className='box'>
      <AnimatedContainer className='news-grid'>
        <div className='applications-content overview span-12'>
          <Glow position='right'/>
          <motion.h2 className='main-title' variants={titleAnimation}>
            Latest News
          </motion.h2>
          <br />
          <br />
          <motion.span className='overview-title' variants={titleAnimation}>
            Building a Future-Ready Workforce: Ohio Skills Taxonomy Project
          </motion.span>
        </div>
        <motion.p
          className='description span-6'
          variants={descriptionAnimation}
        >
          In partnership with the National Science Foundation (NSF), this
          project is pioneering the development of a dynamic skills taxonomy for
          Ohio’s workforce. With industries like Artificial Intelligence (AI),
          Electric Battery Production, and Advanced Manufacturing evolving
          rapidly, the demand for new skills is reshaping the job market.
          Supported by an EAGER grant from the NSF, the initiative aims to track
          skill demand across sectors, identify gaps, and promote workforce
          development through advanced data analysis techniques.
          <br />
          <br />
          Leveraging AI-driven methods like keyword extraction and clustering,
          the project has already delivered important insights. Early results
          reveal significant skill demand in cities like Columbus and Cleveland,
          particularly in AI and clean energy sectors. Heatmaps and skills
          intersection analyses also highlight overlapping competencies between
          industries, suggesting opportunities for cross-sector mobility and
          upskilling.
          <br />
          <br />
          The taxonomy will help organizations across Ohio stay competitive by
          providing real-time data on emerging skills, guiding workforce
          strategies, and informing training programs. With this adaptable
          framework, the project sets the stage for broader national
          implementation, ensuring industries and workers remain aligned with
          future market demands.
        </motion.p>
        <div className='img-container span-4'>
          <img className='logo-nsf' src={LogoNSF} alt='logo-NSF' />
        </div>
      </AnimatedContainer>
      <Maps />
    </div>
  </section>
)

export default News
