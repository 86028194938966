import { motion } from 'motion/react'

const AnimatedContainer = ({ className = '', children }) => (
  <motion.div
    className={className}
    initial='hidden'
    whileInView='visible'
    viewport={{ once: true, amount: 0.5 }}
    variants={{
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1,
          ease: 'easeInOut',
          staggerChildren: 0.25,
        },
      },
    }}
  >
    {children}
  </motion.div>
)

export default AnimatedContainer
