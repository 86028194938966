import * as React from 'react'
import './styles.css'
// assets
import LearningNextSteps from '../../../assets/imgs/learning/learningNextSteps.png'
import LearningProfile from '../../../assets/imgs/learning/learningProfile.png'
import LearningRecomended from '../../../assets/imgs/learning/learningRecomended.png'
import Glow from '../../glows'

const LearningManagementSection = () => {
  return (
    <section className='learning-management-section'>
      <div className='box'>
        <h1 className='learning-management-main-title'>
          Unlock the Full Potential of Your Workforce with LMS-Career Connector
        </h1>
        <div className='learning-management-container'>
          <div className='learning-image-container'>
            <Glow />

            <img
              src={LearningRecomended}
              alt='POD application with recomended upskilling.'
              className='learning-image'
            />
          </div>
          <div className='text-container'>
            <p className='learning-management-title'>
              Turn Learning into Career Growth with Pod + Your LMS
            </p>
            <p className='learning-text'>
              Companies invest in Learning Management Systems (LMS) like
              Coursera, Udemy, and LinkedIn Learning as well as internal
              programs to upskill employees, but too often, employees don't see
              how training connects to real career opportunities within their
              organization. Pod bridges this gap by integrating with your LMS to
              transform learning into clear career progression. Employees can
              explore internal job opportunities, receive personalized course
              recommendations based on their career goals, and track their
              progress—all within one intuitive platform.
            </p>
            <p className='learning-management-title'>
              From Courses to Careers: A Seamless Experience
            </p>
            <p className='learning-text'>
              With Pod, employees don't just take courses—they see how those
              courses directly impact their career path. They can browse
              internal job openings, receive AI-driven suggestions for relevant
              courses, and track how new skills improve their job match scores.
              Completed courses and certifications are automatically added to
              their Pod profile, keeping their career trajectory up to date and
              visible to managers.
            </p>
          </div>
        </div>
        <div className='learning-management-container'>
          <div className='text-container'>
            <p className='learning-management-title'>
              Boost Retention, Reduce Costs, and Future-Proof Your Workforce
            </p>
            <p className='learning-text'>
              Employees often leave companies because they don't see a future
              within them. Pod changes that by making internal mobility clear
              and accessible. Research shows that it costs up to 2x an
              employee's salary to replace them externally, yet internal
              promotions typically cost 20% less and result in faster ramp-up
              times. Companies that invest in internal mobility see 41% higher
              retention rates and 30% more productivity compared to those that
              rely on external hiring. By giving employees a roadmap for growth
              within your organization, Pod helps reduce turnover, cut
              recruitment costs, and improve workforce engagement. Real-time
              skill tracking and targeted upskilling ensure your employees
              develop in ways that align with business needs, keeping your
              company competitive and future-ready.
            </p>
          </div>

          <div className='learning-image-container'>
            <Glow position='right' />

            <img
              src={LearningProfile}
              alt='POD application with a user profile and pathways.'
              className='learning-image'
            />
          </div>
        </div>
        <div className='learning-management-container'>
          <div className='learning-image-container'>
            <Glow />

            <img
              src={LearningNextSteps}
              alt='POD application with interests and next steps shown.'
              className='learning-image'
            />
          </div>
          <div className='text-container'>
            <p className='learning-management-title'>
              Powered Skill Mapping & Career Development
            </p>
            <p className='learning-text'>
              Pod's AI analyzes job descriptions, employee profiles and LMS
              course catalogs to identify skills gaps and recommend personalized
              learning pathways. Employees can preview how specific courses
              impact their career options before enrolling, ensuring every
              training dollar is spent wisely. As they complete courses, their
              newly acquired skills are recognized within Pod, creating a
              dynamic, continuously evolving workforce. By turning learning into
              action, Pod helps companies unlock the full potential of their
              workforce, reduce hiring costs, and build stronger, more agile
              teams.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LearningManagementSection
